.video {
  @apply relative;
  padding-top: 100%;
  background-color: $color__blackish;

  @include responsive(md) {
    padding-top: 56.45%;
  }

  .cld-video-player {
    @apply absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__overlay {
    @apply absolute;
    inset: 0;
    background-position: center;
    background-size: cover;
    transition: .4s visibility ease-in-out, .4s opacity ease-in-out;

    &.is-hidden,
    .lyt-activated & {
      visibility: hidden;
      opacity: 0;
    }

    &:hover {
      cursor: pointer;

      &:hover .video__play {
        background-color: $color__white;
  
        svg {
          fill: $color__blackish;
        }
      }
    }
  }

  &__play {
    @apply flex items-center justify-center;
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    margin-bottom: 1.5rem;
    overflow: hidden;
    border: 2px solid $color__white;
    border-radius: 50%;
    transition: .4s background-color ease-in-out;

    svg {
      fill: $color__white;
    }

    &:hover {
      background-color: $color__white;

      svg {
        fill: $color__blackish;
      }
    }

    &--alone {
      @apply absolute;
      top: 50%;
      left: 50%;
      width: 3.5rem;
      height: 3.5rem;
      transform: translate(-50%, -50%);

      @include responsive(md) {
        width: 5rem;
        height: 5rem;
      }
    }
  }

  iframe,
  video {
    @apply absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/* stylelint-disable-next-line */
lite-youtube {
  @apply flex items-center;
  max-width: none;

  &:before {
    display: none;
  }

  &:after {
    padding-bottom: 100%;

    @include responsive(md) {
      padding-bottom: 56.45%;
    }
  }
}