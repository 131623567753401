.accordion {
  &__content {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: .3s ease-in-out height;

    &.has-overflow {
      overflow: visible;
    }
  }

  &__content-mobile {
    @include max-responsive(md) {
      position: relative;
      height: 0;
      overflow: hidden;
      transition: .3s ease-in-out height;
    }
  }

  &__content-desktop {
    @include responsive(md) {
      position: relative;
      height: 0;
      overflow: hidden;
      transition: .3s ease-in-out height;
    }
  }
}