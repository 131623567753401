.socials {
  &-wrap {
    padding: 2rem 0;
    background-image: linear-gradient(to top,$color__white, #eff1f3);

    @include responsive(md) {
      padding: 2.5rem 0 3rem;
    }
  }

  &-text {
    transition: .3s ease-in-out color;

    @each $name, $color in $socials-colors {
      &#{&}--#{$name} {
        color: $color;
      }
    }
  }

  &__item {
    @apply relative block;
    padding-top: 100%;
    background-color: $color__blue-grey;
    border-right: 1px solid $color__white;
    transition:  .3s ease-in-out background-color;

    span, svg, div {
      pointer-events: none;
    }

    svg {
      @apply absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: .3s ease-in-out opacity;
      fill: $color__white;

      @include max-responsive(md) {
        transform: translate(-50%,-50%) scale(.75);
      }
    }

    &:hover {
      svg {
        opacity: 0;
      }

      .socials__hover {
        opacity: 1;
      }
    }

    @each $name, $color in $socials-colors {
      &#{&}--#{$name}:hover {
        background-color: $color;
      }
    }
  }

  &__hover {
    @apply absolute flex flex-col items-center justify-center text-center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: $color__white;
    line-height: 1.25;
    text-transform: uppercase;
    opacity: 0;
    transition: .3s ease-in-out opacity;
  }
}