/* stylelint-disable */
@tailwind components;

@import 'base/colors';
@import 'base/breakpoints';
@import 'base/vars';

@import 'mixins/ie11';
@import 'mixins/normalize';
@import 'mixins/responsive';
@import 'mixins/sr-only';

@import 'utils/calc-rem';
@import 'utils/easings';
/* stylelint-enable */