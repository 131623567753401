.countries-panel {
  @apply fixed flex;
  top: calc-rem(66);
  right: 0;
  left: 0;
  z-index: 10;
  height: calc(100vh - 66px);
  height: calc((var(--vh, 1vh) * 100) - 66px);
  transform: translateX(-100%);
  transition: .4s ease-in-out transform;
  pointer-events: none;

  @include responsive(lg) {
    @apply absolute;
    top: 0;
    height: 100%;
    transform: translateX(-25%);
  }

  @include responsive(xl) {
    transform: translateX(-20%);
  }

  &.is-active {
    transform: translateX(0);

    .countries-panel__left {
      pointer-events: all;
    }

    .countries-panel__right.is-active {
      pointer-events: all;
    }
  }

  &__continent {
    @apply flex items-center justify-between;
    width: 100%;
    padding: 1rem 1.5rem;
    font-size: 1.25rem;
    text-align: left;
    transition: .4s ease-in-out background-color, .4s ease-in-out color;

    svg {
      pointer-events: none;
      fill: $color__white;
    }

    &:hover,
    &.is-active {
      color: $color__blackish;
      background-color: $color__white;

      svg {
        fill: $color__blackish;
      }
    }

    @include responsive(lg) {
      padding: 0 2.5rem;
    }

    @include responsive(xl) {
      font-size: 1.5rem;
    }
  }

  &__left {
    @apply flex flex-col;
    width: 100%;
    color: $color__white;
    background-color: $color__blue-grey;

    @include responsive(lg) {
      width: 25%;
    }

    @include responsive(xl) {
      width: 20%;
    }
  }

  &__right {
    padding: 2.5rem 1.5rem;
    background-color: $color__white;
    border-top: 1px solid #f1f1f1;
    transform: scaleX(0);
    transform-origin: 0% 0%;
    transition: .4s ease-in-out transform;

    @include max-responsive(lg) {
      @apply fixed;
      top: calc-rem(56);
      right: 0;
      left: 0;
      height: calc(100vh - 122px);
      height: calc((var(--vh, 1vh) * 100) - 122px);
      overflow: auto;
    }

    @include responsive(lg) {
      width: 75%;
      padding: 2rem 0 0 2rem;
    }

    @include responsive(xl) {
      width: 80%;
      padding: calc-rem(72) 0 0 8%;
    }

    &.is-active {
      transform: scaleX(1);
    }
  }

  &__back {
    @apply flex items-center w-full;
    padding: calc-rem(18) 1.5rem;
    color: $color__red;
    background-color: $color__lighter-grey2;

    @include responsive(lg) {
      padding: 1.625rem 2.5rem;
    }

    svg {
      margin-right: .5rem;
      transform: scaleX(-1);
      fill: $color__red;
    }
  }

  &__link {
    text-align: left;

    &:hover {
      color: $color__red;
    }

    span {
      pointer-events: none;
    }
  }

  &__list {
    @apply absolute;
    top: 2rem;
    right: 1.5rem;
    left: 1.5rem;
    display: grid;
    grid-auto-columns: 50%;
    grid-auto-flow: column;
    grid-gap: calc-rem(16);
    grid-template-rows: repeat(9, auto);
    font-size: calc-rem(14);
    visibility: hidden;
    opacity: 0;
    transition: .4s ease-in-out opacity, .4s ease-in-out visibility;

    @include responsive(lg) {
      right: 2rem;
      left: 2rem;
    }

    &.is-active {
      @apply relative;
      top: 0;
      left: 0;
      visibility: visible;
      opacity: 1;
    }

    &--europe {
      grid-template-rows: repeat(27, auto);
    }

    @include responsive(md) {
      grid-auto-columns: 33%;
      grid-template-rows: repeat(10, auto);

      &--europe {
        grid-template-rows: repeat(18, auto);
      }
    }

    @include responsive(lg) {
      grid-auto-columns: 19%;
      grid-template-rows: repeat(10, auto);

      &--europe {
        grid-template-rows: repeat(11, auto);
      }
    }

    @include responsive(xl) {
      top: calc-rem(72);
      left: 10%;
      grid-auto-columns: 25%;

      &--europe {
        grid-template-rows: repeat(13, auto);
      }
    }
  }
}