@use "sass:color";

.countries-select {
  @include max-responsive(lg) {
    order: 2;
  }

  @include responsive(lg) {
    @apply flex flex-col;
    width: 25%;
  }

  @include responsive(xl) {
    width: 20%;
  }

  @include max-responsive(lg) {
    &__world {
      width: 2rem;
      height: 2rem;
    }

    &__target {
      width: 2rem;
      height: 1.5rem;
    }

    &__item svg {
      margin-right: .5rem;
    }
  }

  &__item {
    @apply flex items-center w-full;
    flex-basis: 33.33%;
    min-height: calc-rem(56);
    font-size: 1rem;
    font-family: $font-bold;
    text-align: center;
    transition: .4s ease-in-out background-color, .4s ease-in-out color;

    @include max-responsive(lg) {
      @apply justify-between items-center;
      padding: .75rem 1.5rem;

      svg {
        pointer-events: none;
      }
    }

    @include responsive(lg) {
      @apply flex-col justify-center relative;

      svg:last-child {
        @apply absolute;
        top: 50%;
        right: 1.75rem;
        transform: translateY(-50%);
      }
    }

    &--middle {
      color: $color__white;
      background-color: $color__red;

      svg {
        fill: $color__white;
      }
    }

    &--top {
      font-size: 1.25rem;
      background-color: $color__lighter-grey;

      span {
        display: block;
        font-family: $font-reg;
      }

      svg {
        fill: $color__blackish;
      }

      @include max-responsive(lg) {
        display: none;
      }
    }

    &--bottom {
      color: $color__white;
      background-color: $color__blue-grey;

      svg {
        fill: $color__white;
      }
    }

    &--middle,
    &--bottom {
      &:hover {
        color: $color__blackish;
        background-color: $color__white;

        svg {
          fill: $color__blackish;
        }
      }
    }
  }
}