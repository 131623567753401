.carousel {
  @apply relative w-full;

  @include responsive(lg) {
    width: 75%;
  }

  @include responsive(xl) {
    width: 80%;
  }

  &__infos {
    @apply absolute flex flex-col justify-center items-start;
    top: 0;
    right: 0;
    width: 100%;
    background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.72) 50.53%, #000000 100%);

    @include max-responsive(md) {
      padding: 2rem 1.5rem;
    }

    @include responsive(md) {
      width: 60%;
      height: 100%;
      padding-right: 2rem;
      background: linear-gradient(270deg, #000000 0%, rgba(0,0,0,0.72) 49.47%, rgba(0,0,0,0) 100%);
    }

    @include responsive(lg) {
      width: 50%;
      padding-right: 10%;
    }
  }

  &__title {
    color: $color__white;
    font-size: 2rem;
    font-family: $font-bold;
    line-height: 1.05;

    @include responsive(md) {
      font-size: 2.5rem;
      text-transform: uppercase;
    }
  }

  &__pagination {
    @apply absolute flex justify-center;
    right: 0;
    left: 0;
    z-index: 5;
    padding-bottom: .5rem;
    pointer-events: none;

    @include responsive(md) {
      padding-bottom: 1rem;
    }
  }

  .swiper-pagination-bullet {
    width: .5rem;
    height: .5rem;
    background: transparent;
    border: 1px solid $color__white;
    opacity: 1;
    transition: .4s ease-in-out background-color;
    pointer-events: all;

    @include responsive(md) {
      width: 1rem;
      height: 1rem;
    }

    &-active,
    &:hover {
      background-color: $color__white;
    }
  }

  &__button {
    @apply absolute flex items-center justify-center;
    top: 50%;
    z-index: 5;
    width: 2.5rem;
    height: 2.5rem;
    background-color: rgba($color__black, .5);
    transform: translateY(-50%);
    transition: .4s ease-in-out background-color;

    &:hover {
      background-color: $color__black;
    }

    svg {
      fill: $color__white;
    }

    &--prev {
      left: 0;
      transform: scaleX(-1) translateY(-50%);
    }

    &--next {
      right: 0;
    }

    @include max-responsive(lg) {
      display: none;
    }
  }
}